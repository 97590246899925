import React, { useContext, useEffect } from "react";
import Card from "../components/Card";
import Header from "../components/Header";
import { FiExternalLink, FiInfo } from "react-icons/fi";
import ContentLoader from "react-content-loader";

import Page from "./Page";
import { useGetOverviewQuery } from "../gql/schema";
import {
  XAxis,
  YAxis,
  Tooltip as RechartsTooltip,
  BarChart,
  Bar,
  CartesianGrid,
} from "recharts";
import SentimentIcon from "../components/SentimentIcon";
import Accordion, { AccordionLoader } from "./Accordion";
import Pill from "../components/Pill";
import Tooltip, { TooltipChild } from "../components/Tooltip";
import StatCard from "../components/StatCard";
import { getDelta } from "../components/GrowthComponent";
import userContext from "../contexts/userContext";

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip bg-white shadow rounded border p-2">
        <p className="label">{label}</p>
        {payload.map((entry: any, index: number) => (
          <p key={index} style={{ color: entry.color }}>
            {`${entry.name}: ${Math.round(entry.value)}`}
          </p>
        ))}
      </div>
    );
  }

  return null;
};

const plans = ["starter", "pro", "enterprise"];

const sampleFaqs = [
  {
    question: "How much does Telepathic cost?",
    users: [
      {
        email: "dwight@dundermifflin.com",
        conversationId: null,
      },
      {
        email: "jim@dundermifflin.com",
        conversationId: null,
      },
    ],
  },
  {
    question: "What enterprise case studies have you got?",
    users: [
      {
        email: "dwight@dundermifflin.com",
        conversationId: null,
      },
      {
        email: "pam@dundermifflin.com",
        conversationId: null,
      },
    ],
  },
  {
    question: "Do you integrate with Hubspot?",
    users: [
      {
        email: "meredith@dundermifflin.com",
        conversationId: null,
      },
      {
        email: "stanley@dundermifflin.com",
        conversationId: null,
      },
      {
        email: "kevin@dundermifflin.com",
        conversationId: null,
      },
    ],
  },
];

const sampleNotableCustomers = [
  {
    email: "dwight@dundermifflin.com",
    numResources: 12,
    numSessions: 8,
    score: 89,
  },
  {
    email: "michael@dundermifflin.com",
    numResources: 9,
    numSessions: 9,
    score: 72,
  },
  {
    email: "pam@dundermifflin.com",
    numResources: 8,
    numSessions: 6,
    score: 68,
  },
];

const sampleNotableResources = [
  {
    title: "Analytics demo",
    engagementRate: 8,
    conversionRate: 6,
  },
  {
    title: "Sales deck",
    engagementRate: 12,
    conversionRate: 8,
  },
  {
    title: "Enterprise case study",
    engagementRate: 9,
    conversionRate: 9,
  },
];

const sampleChartData = [
  { page: "Analytics demo", positive: 133, neutral: 93, negative: 93 },
  {
    page: "Sales deck",
    positive: 142,
    neutral: 65,
    negative: 103,
  },
  { page: "Enterprise case study", positive: 109, neutral: 224, negative: 108 },
  { page: "Pricing page", positive: 131, neutral: 183, negative: 93 },
  { page: "Help center", positive: 131, neutral: 224, negative: 130 },
];

type FAQUser = {
  email: string;
  conversationId: string | null;
};

const Overview = () => {
  const [timeFrame, setTimeFrame] = React.useState("week"); // ['day', 'week', 'month'
  const [plan, setPlan] = React.useState("starter"); // ['starter', 'pro', 'enterprise'
  const { user } = useContext(userContext);
  const [isLoading, setIsLoading] = React.useState(false);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setIsLoading(false);
  //   }, 1000);
  // }, []);

  const isTelepathicClient = user?.seller?.client?.name === "Telepathic";
  // const { data, loading } = useGetOverviewQuery({
  //   variables: {
  //     timeFrame,
  //   },
  // });
  // const { data: previousData } = useGetOverviewQuery({
  //   variables: {
  //     timeFrame,
  //     offset: true,
  //   },
  // });
  // const useSampleStats = isTelepathicClient;
  const useSampleStats = true;

  // const chartData = React.useMemo(() => {
  //   const views = data?.getOverview?.keyPages || [];
  //   const result = views
  //     .map((view) => ({
  //       positive: view?.pageSentimentCount?.positive,
  //       neutral: view?.pageSentimentCount?.neutral,
  //       negative: view?.pageSentimentCount?.negative,
  //       page: !!view?.url ? new URL(view?.url).pathname : null,
  //     }))
  //     .filter((view) => !!view.page);

  //   return result;
  // }, [data?.getOverview?.keyPages]);

  // const presentedChartData =
  //   chartData?.length > 0 ? chartData : sampleChartData;
  const presentedChartData = sampleChartData;

  // const useSampleUsers =
  //   isTelepathicClient ||
  //   !data?.getOverview?.keyUsers ||
  //   data?.getOverview?.keyUsers?.length === 0;
  const useSampleUsers = true;

  // const keyUsers = React.useMemo(() => {
  //   if (useSampleUsers) return sampleNotableCustomers;

  //   return data?.getOverview?.keyUsers?.map((u) => ({
  //     user: {
  //       email: u?.email,
  //     },
  //     recentMessages: u?.recentMessages?.map((m) => ({
  //       sentiment: m?.sentiment,
  //       body: m?.body,
  //       conversationId: m?.conversation?.id,
  //     })),
  //   }));
  // }, [data?.getOverview?.keyUsers]);

  // const useSampleFaqs =
  //   isTelepathicClient ||
  //   !data?.getOverview?.keyMessages ||
  //   data?.getOverview?.keyMessages.length === 0;
  const useSampleFaqs = true;
  // const faqs =
  //   React.useMemo(() => {
  //     if (useSampleFaqs) return sampleFaqs;
  //     const result = data?.getOverview?.keyMessages
  //       ?.filter((m) => !!m?.aggregatedBody)
  //       .map((msg) => {
  //         const users: FAQUser[] = [];
  //         if (msg?.messages && msg?.messages.length > 0) {
  //           msg?.messages?.forEach((m) => {
  //             users.push({
  //               email: m.conversation.buyer.email || "Anonymous",
  //               conversationId: m.conversation.id,
  //             });
  //           });
  //         }
  //         return {
  //           question: msg?.aggregatedBody!,
  //           users,
  //         };
  //       });
  //     return result;
  //   }, [data?.getOverview?.keyMessages]) || sampleFaqs;

  const isSampleData = useSampleStats || useSampleUsers || useSampleFaqs;

  // const isLoading = loading;

  const getColor = (num: number) =>
    num > 70
      ? "text-success-green"
      : num > 40
      ? "text-warning-orange"
      : "text-failure-red";

  return (
    <Page childClass="w-4xl max-w-lg lg:max-w-2xl xl:max-w-4xl 2xl:max-w-5xl">
      <Header title="Overview" />
      <div
        className="flex mb-6 items-center justify-start relative mr-8"
        style={{ marginLeft: 1 }}
      >
        <select
          id="countries"
          className="mr-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-main-yellow focus:border-main-yellow block p-2.5 cursor-pointer"
          value={timeFrame}
          onChange={(e) => setTimeFrame(e.target.value)}
        >
          <option>Choose a time period</option>
          <option value="day">Last 24 hours</option>
          <option value="week">Last 7 days</option>
          <option value="month">Last month</option>
          {/* <option value="all-time">All time</option> */}
        </select>

        {isTelepathicClient && (
          <select
            id="countries"
            className="mr-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-main-yellow focus:border-main-yellow block p-2.5 cursor-pointer"
            value={plan}
            onChange={(e) => setPlan(e.target.value)}
          >
            <option>Plan</option>
            {plans.map((c, idx) => (
              <option key={idx} value={c}>
                {c}
              </option>
            ))}
          </select>
        )}
        {isSampleData && !isLoading && (
          <div className="">
            <Tooltip
              itemChild={
                <Pill
                  mainColor="text-link-blue"
                  text="Sample data"
                  className="bg-link-blue/20"
                  icon={<FiInfo />}
                />
              }
              popoverChild={
                <TooltipChild text="You'll see your actual data when you have some!" />
              }
            />
          </div>
        )}
      </div>
      <div className="flex-1 mr-8">
        <div className="flex mb-2 flex-row flex-wrap">
          <StatCard
            title="Conversations started"
            // number={useSampleStats ? 1512 : data?.getOverview?.numMessages}
            number={124}
            // growth={
            //   useSampleStats
            //     ? 11
            //     : getDelta(
            //         data?.getOverview?.numMessages,
            //         previousData?.getOverview?.numMessages
            //       )
            // }
            growth={23}
            loading={isLoading}
            info="Number of user sessions"
          />
          <StatCard
            title="Leads fully qualified"
            // number={useSampleStats ? 982 : data?.getOverview?.numUsers}
            number={124}
            // growth={
            //   useSampleStats
            //     ? -2
            //     : getDelta(
            //         data?.getOverview?.numUsers,
            //         previousData?.getOverview?.numUsers
            //       )
            // }
            growth={11}
            loading={isLoading}
            info="Number of leads with all qualification criteria answered"
          />

          <StatCard
            title="Calls booked"
            // number={useSampleStats ? 487 : data?.getOverview?.numConversations}
            number={35}
            // growth={
            //   useSampleStats
            //     ? 2
            //     : getDelta(
            //         data?.getOverview?.numConversations,
            //         previousData?.getOverview?.numConversations
            //       )
            // }
            growth={9}
            loading={isLoading}
            info="Number of calls with a human booked"
          />
          {/* <StatCard
            title="Pages with questions"
            number={useSampleStats ? 148 : data?.getOverview?.numPages}
            growth={
              useSampleStats
                ? 3
                : getDelta(
                    data?.getOverview?.numPages,
                    previousData?.getOverview?.numPages
                  )
            }
            loading={isLoading}
            info="Number of unique pages with a question asked"
          /> */}
        </div>

        <div className="flex w-full">
          <Card className="w-full mb-6">
            <div className="flex items-center mb-1">
              <div className="font-semibold">Frequently asked questions</div>
            </div>
            <div className="section-parent mb-6">
              {sampleFaqs.map((faq, idx) => (
                <div className="flex items-center justify-start my-2" key={idx}>
                  <div className="flex items-center w-1/2">
                    <div className="flex items-center hover:underline cursor-pointer">
                      {faq.question}
                      <FiExternalLink className="ml-1" />
                    </div>
                  </div>

                  <div className="w-1/2 flex items-center">
                    <div className="text-link-blue flex items-center cursor-pointer hover:underline">
                      Suggested answer
                      <FiExternalLink className="ml-1" />
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="flex items-center mt-6">
              <div className="w-2/5 flex mb-1 font-semibold">Key customers</div>
              <div className="w-1/5 text-gray-500 text-center">
                Resources engaged
              </div>
              <div className="w-1/5 text-gray-500 text-center">Sessions</div>
              <div className="w-1/5 text-gray-500 text-center">Score</div>
            </div>
            <div className="section-parent mb-6">
              {sampleNotableCustomers.map((c, idx) => (
                <div className="flex w-full my-2" key={idx}>
                  <div className="w-2/5 text-left ">
                    <a className="flex items-center hover:underline" href="#">
                      {c.email}
                      <FiExternalLink className="ml-1" />
                    </a>
                  </div>
                  <div className="w-1/5 text-gray-500 text-center">
                    {c.numResources}
                  </div>
                  <div className="w-1/5 text-gray-500 text-center">
                    {c.numSessions}
                  </div>
                  <div
                    className={`w-1/5 text-gray-500 text-center ${getColor(
                      c.score
                    )}`}
                  >
                    {c.score}
                  </div>
                </div>
              ))}
            </div>
            <div className="flex items-center mt-6">
              <div className="w-1/4 flex mb-1 font-semibold">Key resources</div>
              <div className="w-1/4 text-gray-500 text-center">
                Engagement rate
              </div>
              <div className="w-1/4 text-gray-500 text-center">
                Conversion rate
              </div>
            </div>
            <div className="section-parent">
              {sampleNotableResources.map((c, idx) => (
                <div className="flex w-full my-2" key={idx}>
                  <div className="w-1/4 text-left ">
                    <a className="flex items-center hover:underline" href="#">
                      {c.title}
                      <FiExternalLink className="ml-1" />
                    </a>
                  </div>
                  <div className="w-1/4 text-gray-500 text-center">
                    {c.engagementRate}
                  </div>
                  <div className="w-1/4 text-gray-500 text-center">
                    {c.conversionRate}
                  </div>
                </div>
              ))}
            </div>
          </Card>
        </div>
        <div className="flex w-full">
          <Card className="w-full">
            <div className="flex items-center mb-2">
              <div className="font-semibold ">Sentiment per resource</div>
            </div>

            {isLoading ? (
              <ContentLoader
                speed={2}
                width={700}
                height={360}
                viewBox="0 0 800 400"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                style={{
                  marginLeft: 100,
                  marginBottom: 40,
                }}
              >
                {/* Create the five columns using rect elements */}
                <rect x="0" y="150" rx="3" ry="3" width="100" height="250" />
                <rect x="160" y="100" rx="3" ry="3" width="100" height="300" />
                <rect x="320" y="200" rx="3" ry="3" width="100" height="200" />
                <rect x="480" y="50" rx="3" ry="3" width="100" height="350" />
                <rect x="640" y="180" rx="3" ry="3" width="100" height="220" />
              </ContentLoader>
            ) : (
              <BarChart
                width={800}
                height={400}
                data={presentedChartData}
                className=""
              >
                <XAxis dataKey="page" />
                <YAxis
                  label={{
                    value: "Messages",
                    angle: -90,
                    position: "insideLeft",
                  }}
                />
                <CartesianGrid strokeDasharray="3 3" />

                <Bar dataKey="positive" fill="#5F9155" barSize={28} />
                <Bar dataKey="neutral" fill="#9ca3af" barSize={28} />
                <Bar dataKey="negative" fill="#B62C31" barSize={28} />
                <RechartsTooltip content={<CustomTooltip />} cursor={false} />
              </BarChart>
            )}
          </Card>
        </div>
      </div>
    </Page>
  );
};

export default Overview;
