import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../components/Button";
import Header from "../components/Header";
import {
  useDeleteViewMutation,
  useGetViewQuery,
  useSaveViewMutation,
} from "../gql/schema";
import { toast } from "react-toastify";

import Page from "./Page";
import { FiExternalLink } from "react-icons/fi";

const View = () => {
  const { id, demoId } = useParams();
  const { data, loading } = useGetViewQuery({
    variables: {
      id: id || "",
    },
  });
  const [title, setTitle] = React.useState("");
  const [prompts, setPrompts] = React.useState<string>("");
  const [favorite, setFavorite] = React.useState(false);
  const navigate = useNavigate();

  const [saveDemoEvent] = useSaveViewMutation();
  const [deleteDemoEvent] = useDeleteViewMutation();

  React.useEffect(() => {
    if (data?.getView) {
      // setTitle(data.getView.preview || "");
      // setPrompts(data.getView.promptText || "");
      // setFavorite(data.getView.default);
    }
  }, [data?.getView?.id]);

  const handleSubmit = async () => {
    await saveDemoEvent({
      variables: {
        id: data?.getView?.id || "",
        preview: title,
        promptText: prompts,
      },
    });
    toast.success("Saved!");
  };

  const handleDelete = async () => {
    await deleteDemoEvent({
      variables: {
        id,
      },
    });
    toast.success("Deleted!");
    navigate("/");
  };

  const handleReplace = async () => {
    window.localStorage.setItem("telepathic-viewid", id || "");
    window.localStorage.setItem("telepathic-demoid", demoId || "");
    if (data?.getView?.url) {
      window.open(data?.getView?.url, "_blank");
    }
  };

  return (
    <Page childClass="w-xl">
      <Header title={`View${!!title ? `: ${title}` : ""}`} />
      {!loading && !data?.getView ? (
        <div className="h-20 my-8 flex justify-center items-center">
          <div className="text-gray-500 font-medium">
            This view does not exist
          </div>
        </div>
      ) : (
        <>
          <div className="mb-4">
            <div className="screen-container max-w-4xl">
              <div className="relative">
                {!!data?.getView?.html && (
                  <iframe
                    srcDoc={data?.getView?.html}
                    className="w-full h-auto border rounded-md border-gray-300 "
                    style={{ height: 672, pointerEvents: "none" }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="mt-8 flex">
            <div className="mb-6 max-w-2xl">
              <label
                htmlFor="title"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Title
              </label>
              <input
                type="text"
                id="title"
                className="focus:border-white focus:ring-0 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-main-yellow focus:border-main-yellow block w-96 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="No title"
              />
            </div>
            <div className="flex items-center ml-8">
              <input
                checked={!!favorite}
                type="checkbox"
                onChange={(e) => setFavorite(e.target.checked)}
                className="w-4 h-4 text-main-yellow bg-gray-100 border-gray-300 rounded focus:ring-main-yellow dark:focus:ring-main-yellow dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 cursor-pointer disabled:cursor-auto disabled:opacity-40"
              />
              <label className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                Favorite
              </label>
            </div>
          </div>

          <div className="mb-4 flex">
            {/* <div className="mr-8" style={{ width: 384 }}>
          <div className="font-semibold mb-2">AI prompts</div>
          <div className="mb-2 text-gray-500 text-sm">
            Tell the AI what points to make
          </div>
          <textarea
            id="background"
            className="focus:border-white focus:ring-0 bg-gray-50 border border-gray-300 text-gray-700 text-sm rounded-lg focus:outline-main-yellow focus:border-main-yellow block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            required
            value={captions.join("\n")}
            onChange={(e) => setCaptions(e.target.value.split("\n"))}
            rows={8}
          />
        </div> */}

            <div className="w-96 mr-32">
              <div className="font-semibold mb-2">User prompts</div>
              <div className="mb-2 text-gray-500 text-sm">
                Suggest questions for your prospect. Separate with a new line.
              </div>
              <textarea
                id="background"
                className="focus:border-white focus:ring-0 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-main-yellow focus:border-main-yellow block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
                value={prompts}
                onChange={(e) => setPrompts(e.target.value)}
                rows={4}
                placeholder="No prompts set yet"
              />
            </div>
          </div>
          <div>
            <Button onClick={handleSubmit}>Save</Button>
            <Button variant="outlined" className="mr-2" onClick={handleReplace}>
              Record replacement
              <FiExternalLink className="inline-block ml-2" />
            </Button>
            <Button
              variant="outlined"
              className="border-failure-red !text-failure-red"
              onClick={handleDelete}
            >
              Delete
            </Button>
          </div>
        </>
      )}
    </Page>
  );
};

export default View;
