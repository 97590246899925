import React, { useEffect, useRef } from "react";

import { FiCornerDownRight } from "react-icons/fi";

const Prompts = ({ prompts }: { prompts: string[] }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollLeft = containerRef.current.scrollWidth;
    }
  }, [prompts]);

  return (
    <div
      ref={containerRef}
      className="prompts-container flex mt-3 overflow-x-auto w-full"
    >
      {prompts?.map((prompt, idx) => (
        <div
          key={idx}
          className={`border-2 p-2 rounded-lg text-sm font-light ml-2 px-3 bg-transparent whitespace-nowrap text-stone-500 hover:bg-stone-50`}
        >
          <FiCornerDownRight className="inline mr-2 text-main-yellow" />
          {prompt}
        </div>
      ))}
    </div>
  );
};

export default Prompts;
