import React, { createContext, useState } from "react";

type Modal = {
  type: "add-client" | "add-seller";
  info: any;
  ignoreOutsideClick?: boolean;
};

export type ModalContext = {
  modal: Modal | null;
  setModal: (modal: Modal | null) => void;
};

const modalContext = createContext({
  modal: null,
  setModal: () => {},
} as ModalContext);

export const ModalProvider = ({ children }: { children: React.ReactNode }) => {
  const [modal, setModal] = useState<Modal | null>(null);

  return (
    <modalContext.Provider value={{ modal, setModal }}>
      {children}
    </modalContext.Provider>
  );
};

export default modalContext;
