import React from "react";
import { FiCheck } from "react-icons/fi";

type Props = {
  image: string;
  name: string;
  selected: boolean;
  onClick: () => void;
  disabled?: boolean;
};

const ConnectionItem = (props: Props) => {
  return (
    <div
      className={`border flex mr-4 mb-2 p-3 rounded-xl w-64 justify-between items-center ${
        props.selected
          ? "cursor-pointer bg-green-50"
          : props.disabled
          ? ""
          : "cursor-pointer hover:bg-gray-50"
      } `}
      onClick={props.onClick}
    >
      <div className="flex items-center">
        <div
          className={`hover:bg-gray-100 border flex items-center justify-center text-success-green rounded-full radio-button w-4 h-4 border-gray-300 focus:ring-2 focus:bg-main-yellow focus:ring-main-yellow dark:focus:ring-blue-600 dark:focus:bg-main-yellow dark:bg-gray-700 dark:border-gray-600`}
        >
          {props.selected ? <FiCheck fontSize={20} /> : null}
        </div>
        <div
          className={`ml-2 text-sm font-medium dark:text-white ${
            props.disabled ? "text-gray-300" : "text-gray-900"
          }`}
        >
          {props.name}
        </div>
      </div>
      <img
        src={require(`../images/${props.image}`)}
        alt="profile"
        className={`w-6 h-6 rounded-full ml-2 object-cover ${
          props.disabled ? "grayscale opacity-30" : ""
        }`}
      />
    </div>
  );
};

export default ConnectionItem;
