import React, { useContext } from "react";
import Header from "../components/Header";
import Page from "./Page";
import {
  FiArrowLeft,
  FiArrowRight,
  FiExternalLink,
  FiInfo,
} from "react-icons/fi";
import { useListConversationsQuery } from "../gql/schema";
import { DateTime } from "luxon";

import Pill from "../components/Pill";
import Tooltip, { TooltipChild } from "../components/Tooltip";
import Table from "../components/Table";

type RowType = {
  id: string;
  date: string;
  user: string;
  messages: number;
  sessionDuration: number;
  stage:
    | "not-engaged"
    | "messaged"
    | "info-shared"
    | "email-captured"
    | "converted";
  stageColor: string;
  mostEngagedResource: string;
};

const sampleConversations: RowType[] = [
  {
    id: "sample",
    date: "1 day ago",
    user: "dwight@dundermifflin.com",
    messages: 3,
    sessionDuration: 120,
    stage: "converted",
    stageColor: "text-success-green",
    mostEngagedResource: "Resource A",
  },
  {
    id: "sample",
    date: "2 days ago",
    user: "jim@dundermifflin.com",
    messages: 4,
    sessionDuration: 90,
    stage: "email-captured",
    stageColor: "text-success-green",
    mostEngagedResource: "Resource B",
  },
  {
    id: "sample",
    date: "3 days ago",
    user: "pam@dundermifflin.com",
    messages: 5,
    sessionDuration: 150,
    stage: "converted",
    stageColor: "text-success-green",
    mostEngagedResource: "Resource C",
  },
  {
    id: "sample",
    date: "4 days ago",
    user: "kelly@dundermifflin.com",
    messages: 2,
    sessionDuration: 60,
    stage: "not-engaged",
    stageColor: "text-failure-red",
    mostEngagedResource: "Resource D",
  },
];

const itemsPerPage = 30;

const ActivityList = () => {
  const [currentPage, setCurrentPage] = React.useState(0);

  const offset = currentPage * itemsPerPage;
  const limit = itemsPerPage;

  const { data, loading, error } = useListConversationsQuery({
    variables: {
      limit,
      offset,
    },
    fetchPolicy: "cache-and-network",
  });
  const useSampleData = data?.listConversations?.length === 0;

  const conversationsData: RowType[] = React.useMemo(() => {
    if (useSampleData) {
      return sampleConversations;
    }
    if (data?.listConversations) {
      return (data?.listConversations || [])
        .filter((c) => !!c?.messages?.length)
        .map((c) => ({
          id: c?.id,
          date:
            DateTime.fromISO(c?.createdAt)
              .toRelative()
              ?.replace("ago", "")
              .trim() || "N/A",
          user:
            c?.buyer?.encryptedEmail ||
            c?.buyer?.id.replace(/\D/g, "").slice(0, 6),
          messages: c?.messages.length || 0,
          sessionDuration:
            (c?.messages || []).length > 0
              ? Math.floor(
                  (new Date(
                    c?.messages[c.messages.length - 1]?.createdAt
                  ).getTime() -
                    new Date(c?.createdAt).getTime()) /
                    1000
                )
              : 0,
          stage: c
            ? c?.buyer?.encryptedEmail
              ? "email-captured"
              : c?.messages?.length > 0
              ? "messaged"
              : "not-engaged"
            : "not-engaged",
          stageColor: c?.buyer?.encryptedEmail
            ? "text-success-green"
            : (c?.messages || [])?.length > 0
            ? "text-warning-orange"
            : "text-failure-red",
          mostEngagedResource:
            c?.messages.length === 0
              ? "N/A"
              : (c?.messages || [])
                  .slice()
                  .sort(
                    (a, b) =>
                      new Date(a.createdAt).getTime() -
                      new Date(b.createdAt).getTime()
                  )
                  .filter((m) => m.messageViews.some((mv) => mv.view.image))
                  .map((m, index, arr) => {
                    if (index === 0) return { timeDiff: 0, preview: null }; // Skip the first message
                    const prevMessageTime = new Date(
                      arr[index - 1].createdAt
                    ).getTime();
                    const currentMessageTime = new Date(m.createdAt).getTime();
                    const timeDiff =
                      (currentMessageTime - prevMessageTime) / 1000; // Time difference in seconds
                    const preview = m.messageViews.find((mv) => mv.view.image)
                      ?.view.preview;
                    return { timeDiff, preview };
                  })
                  .reduce(
                    (longest, current) => {
                      return current.timeDiff > longest.timeDiff
                        ? current
                        : longest;
                    },
                    { timeDiff: 0, preview: null }
                  ).preview || "N/A",
        }));
    }
    return [];
  }, [data, useSampleData]);

  // Pagination handlers
  const nextPage = () =>
    setCurrentPage((prev) => Math.min(prev + 1, pageCount - 1));
  const prevPage = () => setCurrentPage((prev) => Math.max(prev - 1, 0));
  const pageCount = Math.max(
    1,
    Math.ceil((data?.countConversations || 0) / itemsPerPage)
  );
  const labels = {
    user: "User",
    date: "Time ago",
    messages: "Messages",
    sessionDuration: "Session duration",
    stage: "Stage",
    mostEngagedResource: "Most engaged resource",
  };

  return (
    <Page childClass="max-w-7xl overflow-hidden" parentClass="!overflow-hidden">
      <Header title="Activity" />
      <div className="flex-1 table-parent mr-16 flex flex-col overflow-hidden">
        {useSampleData && (
          <div className="flex items-start mb-4">
            <Tooltip
              itemChild={
                <Pill
                  mainColor="text-link-blue"
                  text="Sample data"
                  className="bg-link-blue/20"
                  icon={<FiInfo />}
                />
              }
              popoverChild={
                <TooltipChild text="You'll see your actual data when you have some!" />
              }
            />
          </div>
        )}

        <Table
          labels={labels}
          data={conversationsData}
          loading={loading}
          error={error}
          emptyMessage="No activity yet"
          customRenders={{
            user: (value, row) => (
              <a
                href={`/activity/${row.id}`}
                className="hover:underline flex items-center cursor-pointer"
              >
                <FiExternalLink className="mr-2" />
                {value}
              </a>
            ),
            stage: (value, row) => (
              <span className={row.stageColor}>{value}</span>
            ),
          }}
        />
        {!loading && !error && conversationsData.length !== 0 && (
          <div className="flex justify-start items-center mt-4">
            <div className="text-gray-500 text-sm">
              Page {currentPage + 1} of {pageCount}
            </div>
            <div className="flex items-center">
              <Tooltip
                itemChild={
                  <button
                    className={`border rounded-md p-2 cursor-pointer text-gray-500 ml-2 ${
                      currentPage === 0
                        ? "!text-gray-300 border-gray-100"
                        : "hover:bg-gray-100"
                    }`}
                    onClick={prevPage}
                    disabled={currentPage === 0}
                  >
                    <FiArrowLeft className="" />
                  </button>
                }
                popoverChild={
                  currentPage !== 0 && <TooltipChild text="Previous page" />
                }
              />

              <Tooltip
                itemChild={
                  <button
                    className={`border rounded-md p-2 cursor-pointer hover:bg-gray-100 text-gray-500 ml-2`}
                    onClick={nextPage}
                    disabled={currentPage >= pageCount - 1}
                  >
                    <FiArrowRight className="" />
                  </button>
                }
                popoverChild={<TooltipChild text="Next page" />}
              />
            </div>
          </div>
        )}
      </div>
    </Page>
  );
};

export default ActivityList;
