import React from "react";
import { ModalContent } from "./Modal";
import TextInput from "../components/TextInput";
import Button from "../components/Button";
import { useAddClientMutation } from "../gql/schema";
import modalContext from "../contexts/modalContext";
import { toast } from "react-toastify";
import useEnterSubmit from "../hooks/useEnterSubmit";
import { VscLoading } from "react-icons/vsc";

const AddClientModal = () => {
  const [name, setName] = React.useState("");
  const [websiteUrl, setWebsiteUrl] = React.useState("");
  const [error, setError] = React.useState("");
  const [addClient] = useAddClientMutation();
  const [loading, setLoading] = React.useState(false);
  const { setModal } = React.useContext(modalContext);

  const handleSubmit = async () => {
    if (!name) {
      setError("Please fill in the name");
      return;
    }
    if (!websiteUrl) {
      setError("Please fill in the webiste url");
      return;
    }
    setLoading(true);

    try {
      const response = await addClient({
        variables: {
          name,
          websiteUrl,
        },
        refetchQueries: ["ListClients"],
      });
      if (response?.data?.addClient?.client?.id) {
        toast.success("Client added: " + name);
        setError("");
        setName("");
        setWebsiteUrl("");
        setModal(null);
      } else {
        setError(
          response?.data?.addClient?.error?.message || "Error adding client"
        );
      }
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  useEnterSubmit(handleSubmit);

  return (
    <ModalContent title="Add client">
      <div className="flex flex-col w-80">
        <div className="mb-4 mr-4">
          <label
            htmlFor="text"
            className="block mb-1 text-sm font-semibold text-gray-900"
          >
            Name
          </label>
          <TextInput
            placeholder="Client name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            style={{}}
            autoFocus
          />
        </div>
        <div className="mb-4 mr-4">
          <label
            htmlFor="text"
            className="block mb-1 text-sm font-semibold text-gray-900"
          >
            Website url
          </label>
          <TextInput
            placeholder="https://example.com"
            value={websiteUrl}
            onChange={(e) => setWebsiteUrl(e.target.value)}
          />
        </div>
      </div>
      {error && <div className="text-failure-red mb-4">{error}</div>}

      <div className="flex justify-start items-center mt-2">
        <Button className="" onClick={handleSubmit}>
          Add
        </Button>
        {loading && (
          <VscLoading className="ml-2 animate-spin-fast text-gray-500" />
        )}
      </div>
    </ModalContent>
  );
};

export default AddClientModal;
