import React from "react";
import { ModalContent } from "./Modal";
import TextInput from "../components/TextInput";
import modalContext from "../contexts/modalContext";
import useEnterSubmit from "../hooks/useEnterSubmit";
import Button from "../components/Button";
import { VscLoading } from "react-icons/vsc";
import { toast } from "react-toastify";
import { useAddSellerMutation } from "../gql/schema";

const AddSellerModal = () => {
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const { setModal } = React.useContext(modalContext);
  const [addSeller] = useAddSellerMutation();

  const handleSubmit = async () => {
    if (!firstName || !lastName) {
      setError("Please fill in the name");
      return;
    }
    if (!email) {
      setError("Please fill in the email");
      return;
    }

    setLoading(true);

    try {
      const response = await addSeller({
        variables: {
          firstName,
          lastName,
          email,
        },
        refetchQueries: ["ListSellers"],
      });
      if (response?.data?.addSeller?.seller?.id) {
        toast.success(
          "User added and password copied to clipboard - store safely now to avoid losing it.",
          { autoClose: 10000 }
        );
        // Copy password to clipboard
        navigator.clipboard.writeText(
          response?.data?.addSeller?.seller?.user?.password
        );
        setError("");
        setFirstName("");
        setLastName("");
        setEmail("");
        setModal(null);
      } else {
        setError(
          response?.data?.addSeller?.error?.message || "Error adding client"
        );
      }
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  useEnterSubmit(handleSubmit);

  return (
    <ModalContent title="Add user">
      <div className="flex flex-col w-80">
        <div className="mb-4 mr-4">
          <label
            htmlFor="text"
            className="block mb-1 text-sm font-semibold text-gray-900"
          >
            First name
          </label>
          <TextInput
            placeholder="Joe"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            style={{}}
            autoFocus
          />
        </div>
        <div className="mb-4 mr-4">
          <label
            htmlFor="text"
            className="block mb-1 text-sm font-semibold text-gray-900"
          >
            Last name
          </label>
          <TextInput
            placeholder="Smith"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            style={{}}
          />
        </div>
        <div className="mb-4 mr-4">
          <label
            htmlFor="text"
            className="block mb-1 text-sm font-semibold text-gray-900"
          >
            Email
          </label>
          <TextInput
            placeholder="example@email.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
      </div>
      {error && <div className="text-failure-red mb-4">{error}</div>}

      <div className="flex justify-start items-center mt-2">
        <Button className="" onClick={handleSubmit}>
          Add
        </Button>
        {loading && (
          <VscLoading className="ml-2 animate-spin-fast text-gray-500" />
        )}
      </div>
    </ModalContent>
  );
};

export default AddSellerModal;
