import { FiAirplay, FiInfo } from "react-icons/fi";
import DefaultBg from "../images/default-bg.png";
import Tooltip, { TooltipChild } from "../components/Tooltip";
import Pill from "../components/Pill";
import { useParams } from "react-router-dom";

type Props = {
  imageUrl: string | null;
};

const ConversationReviewScreen = (props: Props) => {
  const { id } = useParams();

  return (
    <div className="resource-parent flex-1 flex flex-col items-center justify-center relative rounded-lg w-full">
      {id === "sample" && (
        <div className="absolute top-0 left-0 flex flex-start mb-4">
          <div>
            <Tooltip
              itemChild={
                <Pill
                  mainColor="text-link-blue"
                  text="Sample data"
                  className="bg-link-blue/20"
                  icon={<FiInfo />}
                />
              }
              popoverChild={
                <TooltipChild text="Here's a sample conversation" />
              }
            />
          </div>
        </div>
      )}
      <div
        className={`flex items-center bg-resource-gray rounded-t-lg p-1 px-3 text-white font-light ${
          !!props.imageUrl ? "opacity-100" : "opacity-0"
        }`}
        style={{
          zIndex: -1,
        }}
      >
        <FiAirplay className="mr-2 text-main-yellow" />
        Telepathic presenting now
      </div>

      <div className="relative w-full h-full bg-resource-gray rounded-lg flex items-center justify-center">
        <img
          src={props.imageUrl || DefaultBg}
          alt="bg"
          className={` ${
            props.imageUrl ? "object-contain" : "object-cover"
          } rounded-lg `}
          style={{
            height: "auto",
            width: "auto",
            maxWidth: "calc(100% - 32px)",
            maxHeight: "calc(100% - 32px)",
          }}
        />
        {!props.imageUrl && (
          <div className="absolute bottom-4 left-8 text-gray-300 font-light">
            Telepathic
          </div>
        )}
      </div>
    </div>
  );
};

export default ConversationReviewScreen;
