import React, { useContext } from "react";
import { useGetMeLazyQuery } from "../gql/schema";
import authContext from "./authContext";
import useTestingRedirect from "../hooks/useTestingRedirect";
import { toast } from "react-toastify";
export type UserType = any;

interface UserContext {
  user: UserType;
  userLoading: boolean;
}

const userContext = React.createContext<UserContext>({
  user: null,
  userLoading: false,
});

export const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = React.useState<UserType>(null);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [getUser, {}] = useGetMeLazyQuery();

  const { authToken, signOut } = useContext(authContext);

  const fetchUserDetails = React.useCallback(async () => {
    if (!authToken) {
      toast.error("Login credentials not found");
      signOut();
      return;
    }
    try {
      setLoading(true);
      const response = await getUser({
        context: {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        },
      });
      if (!!response?.data?.getMe && !response?.data?.getMe?.seller) {
        toast.error("You are not authorized to access this app");
        signOut();
        return;
      }
      setUser(response.data?.getMe || null);
    } catch (e) {}
    setLoading(false);
  }, [authToken]);

  React.useEffect(() => {
    fetchUserDetails();
  }, [fetchUserDetails]);

  useTestingRedirect(
    user,
    !!user && user?.seller?.client?.plan === "testing" && !user?.isAdmin
  );

  return (
    <userContext.Provider
      value={{
        user,
        userLoading: loading,
      }}
    >
      {children}
    </userContext.Provider>
  );
};

export default userContext;
