import React from "react";
import "./App.css";
import {
  BrowserRouter,
  Route,
  Routes,
  useLocation,
  Navigate,
  Outlet,
} from "react-router-dom";
import Login from "./login/Login";
import ConnectWith from "./views/ConnectWith";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import onboardingContext, {
  OnboardingProvider,
} from "./contexts/onboardingContext";
import { UserProvider } from "./contexts/userContext";
import authContext from "./contexts/authContext";
import Overview from "./views/Overview";
import ActivityList from "./views/ActivityList";
import Activity from "./views/Activity";
import ViewsList from "./views/ViewsList";
import View from "./views/View";
import { ToastContainer } from "react-toastify";
import { FiX } from "react-icons/fi";
import ChromeExtension from "./views/ChromeExtension";
import Admin from "./views/Admin";
import Dojo from "./views/Dojo";
import TokenCheck from "./views/TokenCheck";
import ResetPassword from "./login/ResetPassword";
import CheckUser from "./views/CheckUser";
import Modal from "./modals/Modal";
import { ModalProvider } from "./contexts/modalContext";

const NoMatch = () => {
  return <div>404</div>;
};

const RequireAuth = () => {
  let { authToken } = React.useContext(authContext);
  let location = useLocation();
  const { setOnboardingState } = React.useContext(onboardingContext);
  const client = React.useMemo(
    () =>
      new ApolloClient({
        uri: `${process.env.REACT_APP_API_ROOT}/api/graphql/`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        cache: new InMemoryCache(),
      }),
    [authToken]
  );

  if (!authToken) {
    setOnboardingState("email");
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return (
    <ApolloProvider client={client}>
      <UserProvider>
        <Outlet />
        <Modal />
      </UserProvider>
    </ApolloProvider>
  );
};

const AlreadyLoggedIn = () => {
  let { authToken } = React.useContext(authContext);
  let location = useLocation();

  if (authToken) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return <Outlet />;
};

function App() {
  return (
    <OnboardingProvider>
      <ModalProvider>
        <BrowserRouter>
          <Routes>
            <Route element={<RequireAuth />}>
              <Route path="/chrome-extension" element={<ChromeExtension />} />
              <Route
                path="/activity/:id/messages/:messageId"
                element={<Activity />}
              />
              <Route path="/activity/:id" element={<Activity />} />
              <Route path="/activity" element={<ActivityList />} />
              <Route path="/demos/:demoId/views/:id" element={<View />} />
              <Route path="/views" element={<ViewsList />} />
              <Route path="/integrations" element={<ConnectWith />} />
              <Route path="/activity" element={<ActivityList />} />
              <Route path="/admin" element={<Admin />} />
              <Route path="/dojo" element={<Dojo />} />
              <Route path="/token-check" element={<TokenCheck />} />

              <Route path="/" element={<CheckUser />} />
              <Route path="/dashboard" element={<Overview />} />
              <Route path="*" element={<Overview />} />
            </Route>
            <Route path="/login" element={<Login />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route element={<AlreadyLoggedIn />}>
              {/* <Route path="/sign-up" element={<Login />} /> */}
            </Route>
          </Routes>
        </BrowserRouter>
        <ToastContainer
          position="bottom-center"
          hideProgressBar
          autoClose={5000}
          newestOnTop={true}
          closeOnClick
          pauseOnHover
          pauseOnFocusLoss
          theme="light"
          draggable={false}
          closeButton={<FiX size={20} />}
          style={{
            padding: "12px",
            borderRadius: "12px",
            maxWidth: "none", // Add this line to remove width limit
          }}
        />
      </ModalProvider>
    </OnboardingProvider>
  );
}

export default App;
