import React from "react";
import Card from "./Card";
import Button from "./Button";
import Tooltip, { TooltipChild } from "./Tooltip";

import { FiFile, FiX, FiInfo } from "react-icons/fi";

type FileUploadProps = {
  files: File[];
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
};

const FileUpload = ({ files, setFiles }: FileUploadProps) => {
  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFiles((prevFiles) => [
      ...prevFiles,
      ...Array.from(e.target.files || []),
    ]);
  };

  return (
    <Card>
      <div className="">
        <label
          htmlFor="description"
          className="mb-2 text-sm font-semibold text-gray-900 dark:text-white flex items-center"
        >
          Demo content upload
          <Tooltip
            itemChild={<FiInfo className="ml-1 text-gray-500" />}
            popoverChild={
              <TooltipChild
                text={
                  "This is the content shown in the middle of the screen during the demo. For now we only accept PDFs."
                }
              />
            }
          ></Tooltip>
        </label>
        <Button
          variant="outlined"
          className="mb-2"
          onClick={() => document.getElementById("fileInput")?.click()}
        >
          Upload file
        </Button>
        <input
          type="file"
          id="fileInput"
          style={{ display: "none" }}
          onChange={handleFileUpload}
        />
        {files.length > 0 && (
          <div className="flex items-center flex-wrap">
            {files.map((f) => (
              <div
                key={f.name}
                className="text-gray-500 mb-2 text-sm hover:bg-gray-50 cursor-pointer mr-2 border border-gray-300 rounded-lg p-1 px-2 flex items-center justify-center"
                onClick={() => setFiles(files.filter((ff) => ff !== f))}
              >
                <FiFile className="mr-1 h-4 w-4 text-main-yellow" />
                {f.name}
                <FiX className="ml-1 h-3 w-3" />
              </div>
            ))}
          </div>
        )}
      </div>
    </Card>
  );
};

export default FileUpload;
