import React from "react";
import Page from "../views/Page";
import authContext from "../contexts/authContext";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import onboardingContext from "../contexts/onboardingContext";
import Button from "../components/Button";

const LoginOrSignup = () => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const { setAuthToken } = React.useContext(authContext);
  const [error, setError] = React.useState("");
  const navigate = useNavigate();
  const location = useLocation();
  // const newUser = location?.pathname?.includes("/sign-up");
  const newUser = false;
  const { setOnboardingState } = React.useContext(onboardingContext);

  const handleSubmit = async () => {
    try {
      if (false) {
        const response = await fetch(
          `${process.env.REACT_APP_API_ROOT}/auth/users/`,
          {
            method: "POST",
            body: JSON.stringify({ email, password }),
            headers: {
              "Content-Type": "application/json",
            },
            mode: "cors",
            credentials: "same-origin",
          }
        );
        const data = await response.json();
        if (response.status === 400) {
          setError(data);
          return { errors: data };
        } else if (response.status === 401) {
          setError("Email or password incorrect");
        } else if (response.status === 201) {
          toast.success("Signed up successfully!");
          setError("");
          navigate("/login");
          return;
        }
      } else {
        const response = await fetch(
          `${process.env.REACT_APP_API_ROOT}/auth/jwt/create`,
          {
            method: "POST",
            body: JSON.stringify({ email, password }),
            headers: {
              "Content-Type": "application/json",
            },
            mode: "cors",
            credentials: "same-origin",
          }
        );
        const data = await response.json();
        if (response.status === 400) {
          setError(data);
          return { errors: data };
        } else if (response.status === 401) {
          setError("Email or password incorrect");
        } else if (response.status === 200 && data.access) {
          const token = data.access;
          toast.success("Logged in successfully!");
          setAuthToken(token);
          setError("");
          console.log("token: " + token);
          setOnboardingState("onboarded");
          navigate("/");
          window.location.reload();
          return;
        }
      }
    } catch (e) {
      console.log(e);
      setError("Something went wrong");
    }
  };

  return (
    <Page showSidebar={false}>
      <div className="max-w-lg flex justify-center items-center w-full flex-1">
        <div className="w-80 h-max-content">
          <div className="mb-6">
            <h3 className="text-2xl font-bold mb-6">
              {newUser ? "Sign up" : "Log in"}
            </h3>

            <label
              htmlFor="email"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              className="focus:ring-0 bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:outline-main-yellow rounded-lg focus:border-main-yellow block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              required
              value={email}
              onChange={(e) => {
                setError("");
                setEmail(e.target.value);
              }}
            />
          </div>
          <div className="mb-6">
            <label
              htmlFor="password"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Password
            </label>
            <input
              type="password"
              id="password"
              className="focus:ring-0 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-main-yellow focus:border-main-yellow block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              required
              value={password}
              onChange={(e) => {
                setError("");
                setPassword(e.target.value);
              }}
            />
          </div>
          {error && (
            <div className="text-failure-red text-sm mb-4">{error}</div>
          )}

          <div className="flex items-center">
            <Button onClick={handleSubmit}>Continue</Button>
            <div className="flex-1 flex justify-end items-center"></div>
          </div>
          {/* <div className="text-gray-400 mt-2">
            {newUser ? "Already registered? " : "No account? "}
            <a
              href={newUser ? "/login" : "/sign-up"}
              className="text-link-blue hover:underline"
            >
              {newUser ? "Log in" : "Sign up"}
            </a>
          </div> */}
        </div>
        {/* <div className="w-full border-b-2 mt-6 mb-6 relative flex justify-center items-center">
          <div className="absolute text-gray-400 bg-white px-2">
            or continue with
          </div>
        </div>
        <div className="flex justify-start items-center">
          <button
            type="button"
            className="flex items-center active:bg-gray-100 text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-hover-yellow focus:ring-gray-200 font-medium rounded-xl text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
          >
            <img
              src={require("../images/google.svg").default}
              alt="mySvgImage"
              className="h-4 w-4 mr-2"
            />
            Google
          </button>
          <button
                type="button"
                className="text-gray-900 bg-white active:bg-gray-100 border border-gray-300 focus:outline-none hover:bg-hover-yellow focus:ring-gray-200 font-medium rounded-xl text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
              >
                LinkedIn
              </button> 
        </div> */}
      </div>
    </Page>
  );
};

export default LoginOrSignup;
