import React from "react";
import modalContext from "../contexts/modalContext";
import Card from "../components/Card";
import AddClientModal from "./AddClientModal";
import AddSellerModal from "./AddSellerModal";

type Props = {};

type ContentProps = {
  title: string;
  children: React.ReactNode;
};

export const ModalContent = (props: ContentProps) => {
  return (
    <Card className="bg-white !mr-0 !mb-0 shadow-2xl shadow-gray-700 max-w-screen-md p-0">
      <h2 className="text-lg font-semibold">{props.title}</h2>
      <div className="mt-2">{props.children}</div>
    </Card>
  );
};

const Modal = (props: Props) => {
  const { modal, setModal } = React.useContext(modalContext);

  const onClose = React.useCallback(
    (e: any) => {
      if (!!modal) {
        !modal?.ignoreOutsideClick && setModal(null);
      }
    },
    [modal]
  );

  React.useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape" && modal) {
        onClose(event);
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [modal]);

  const handleBgClick = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (event.target === event.currentTarget) {
        onClose(event);
      }
    },
    [onClose]
  );

  if (!modal) return null;
  return (
    <div
      className="z-50 fixed inset-0 flex w-screen items-center justify-center p-4 bg-black/20"
      onClick={handleBgClick}
    >
      {modal?.type === "add-client" ? (
        <AddClientModal {...modal?.info} />
      ) : modal?.type === "add-seller" ? (
        <AddSellerModal {...modal?.info} />
      ) : null}
    </div>
  );
};

export default Modal;
