import React from "react";
import Page from "../views/Page";
import Button from "../components/Button";
import TextInput from "../components/TextInput";
import { VscLoading } from "react-icons/vsc";
import IconButton from "../components/IconButton";
import { MdPassword } from "react-icons/md";
import Tooltip, { TooltipChild } from "../components/Tooltip";
import { toast } from "react-toastify";
import authContext from "../contexts/authContext";

import onboardingContext from "../contexts/onboardingContext";
import { useNavigate } from "react-router-dom";

const ResetPassword = () => {
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const { setAuthToken } = React.useContext(authContext);
  const navigate = useNavigate();
  const { setOnboardingState } = React.useContext(onboardingContext);

  const email = React.useMemo(() => {
    const url = new URL(window.location.href);
    const emailParam = url.searchParams.get("email");
    return emailParam ? emailParam.replace(/ /g, "+") : "";
  }, [window.location.href]);

  const token = React.useMemo(() => {
    const url = new URL(window.location.href);
    return url.searchParams.get("token");
  }, [window.location.href]);

  const handleSubmit = async () => {
    if (!email || !password) {
      setError("Please fill in all fields");
      return;
    } else if (password.length < 8) {
      setError("Password must be at least 8 characters long");
      return;
    } else if (!/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/.test(password)) {
      setError(
        "Password must contain at least one uppercase letter, one lowercase letter and one number"
      );
      return;
    }

    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_ROOT}/stakeholders/reset_password/`,
        {
          method: "POST",
          body: JSON.stringify({ email, password, token }),
          headers: { "Content-Type": "application/json" },
          mode: "cors",
          credentials: "same-origin",
        }
      );
      const data = await response.json();
      if (data.error || !data.token) {
        setError(data.error);
        setLoading(false);
      } else {
        setAuthToken(data.token);
        setOnboardingState("onboarded");
        toast.success("Password set successfully, logging in now...");
        setTimeout(() => {
          navigate("/");
          window.location.reload();
        }, 2000);
      }
    } catch (error) {
      console.error(error);
      setError("Failed to reset password");
    }
    setLoading(false);
  };

  const generateRandomPassword = () => {
    // Generate a radom password of numbers and letters (upper & lower case) of length 24
    const randomPassword = Array.from(
      { length: 24 },
      () =>
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"[
          Math.floor(Math.random() * 62)
        ]
    ).join("");
    setPassword(randomPassword);
    // Copy the password to the clipboard
    navigator.clipboard.writeText(randomPassword);
    toast.success("Password copied to clipboard");
  };

  return (
    <Page showSidebar={false}>
      <div className="max-w-lg flex justify-center items-center w-full flex-1">
        <div className="w-80 h-max-content">
          <form>
            <div className="mb-6">
              <h3 className="text-2xl font-bold mb-6">Password reset</h3>

              <label
                htmlFor="email"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Email
              </label>
              <TextInput
                type="email"
                id="email"
                required
                disabled
                value={email || ""}
              />
            </div>
            <div className="mb-6 relative">
              <label
                htmlFor="password"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Password
              </label>
              <TextInput
                type="password"
                id="password"
                required
                value={password}
                onChange={(e) => {
                  setError("");
                  setPassword(e.target.value);
                }}
              />
              <div className="absolute right-2 top-8">
                <Tooltip
                  itemChild={
                    <IconButton
                      onClick={generateRandomPassword}
                      className="bg-white text-main-yellow"
                    >
                      <MdPassword />
                    </IconButton>
                  }
                  popoverChild={
                    <TooltipChild text="Generate random password" />
                  }
                />
              </div>
            </div>
            {error && (
              <div className="text-failure-red text-sm mb-4">{error}</div>
            )}

            <div className="flex items-center">
              <Button onClick={handleSubmit}>Continue</Button>

              {loading && (
                <VscLoading className="animate-spin-fast text-gray-500" />
              )}
            </div>
          </form>
        </div>
      </div>
    </Page>
  );
};

export default ResetPassword;
