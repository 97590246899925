import React from "react";
import Card from "./Card";
import Tooltip, { TooltipChild } from "./Tooltip";
import { FiInfo, FiX, FiPlus } from "react-icons/fi";
import { MdDragIndicator } from "react-icons/md";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import TextInput from "./TextInput";
import IconButton from "./IconButton";
import { VscLoading } from "react-icons/vsc";
import { Question } from "../views/Dojo";
import { useGenerateQuestionKeyMutation } from "../gql/schema";

type QuestionListProps = {
  questions: Question[];
  setQuestions: React.Dispatch<React.SetStateAction<Question[]>>;
  newQuestion: string;
  setNewQuestion: React.Dispatch<React.SetStateAction<string>>;
  loading: boolean;
};

const QuestionItem = ({
  question,
  provided,
  questions,
  setQuestions,
}: {
  question: Question;
  provided: any;
  questions: Question[];
  setQuestions: React.Dispatch<React.SetStateAction<Question[]>>;
}) => {
  return (
    <div
      className="mb-2 flex items-center rounded-lg relative cursor-auto hover:bg-gray-50 p-1 px-2 group"
      ref={provided.innerRef}
      {...provided.draggableProps}
    >
      <div
        {...provided.dragHandleProps}
        className="cursor-grab opacity-0 group-hover:opacity-100"
      >
        <MdDragIndicator className="text-gray-400" />
      </div>
      <div
        contentEditable
        suppressContentEditableWarning
        className="cursor-auto ml-2 text-gray-700 focus:border-gray-50 focus:ring-0 focus:outline-main-yellow whitespace-nowrap overflow-hidden "
        onBlur={(e) => {
          const updatedQuestions = [...questions];
          const questionIndex = updatedQuestions.findIndex(
            (item) => item.key === question.key
          );
          if (questionIndex !== -1) {
            updatedQuestions[questionIndex] = {
              ...updatedQuestions[questionIndex],
              question: e.currentTarget.textContent || "",
            };
            setQuestions(updatedQuestions);
          }
        }}
      >
        {question.question}
      </div>
      {/* <div
        contentEditable
        suppressContentEditableWarning
        className="cursor-auto ml-1 text-gray-400 focus:border-gray-50 focus:ring-0 focus:outline-main-yellow overflow-hidden whitespace-nowrap text-ellipsis"
        onBlur={(e) => {
          const updatedQuestions = [...questions];
          const questionIndex = updatedQuestions.findIndex(
            (item) => item.key === question.key
          );
          if (questionIndex !== -1) {
            updatedQuestions[questionIndex] = {
              ...updatedQuestions[questionIndex],
              key: e.currentTarget.textContent || "",
            };
            setQuestions(updatedQuestions);
          }
        }}
      >
        {question.key}
      </div> */}
      <div
        onClick={() => {
          setQuestions((prev) => prev.filter((item) => item !== question));
        }}
        className="absolute right-2 cursor-pointer text-gray-500 opacity-0 group-hover:opacity-100 hover:bg-gray-200 rounded-full p-1"
      >
        <FiX />
      </div>
    </div>
  );
};

const QuestionList = ({
  questions,
  setQuestions,
  newQuestion,
  setNewQuestion,
  loading,
}: QuestionListProps) => {
  const requiredQuestions = questions.filter((q) => q.required === "required");
  const optionalQuestions = questions.filter((q) => q.required === "optional");

  const draggableQuestions = [
    ...requiredQuestions,
    { question: "", key: "divider", required: null }, // Divider with empty fields
    ...optionalQuestions,
  ];

  const [generateQuestionKey, { loading: generateQuestionKeyLoading }] =
    useGenerateQuestionKeyMutation();

  const handleGenerateQuestionKey = async (question: string) => {
    const response = await generateQuestionKey({ variables: { question } });
    return response.data?.generateQuestionKey?.key;
  };

  return (
    <Card className="mb-6">
      {loading ? (
        <div className="text-center flex items-center justify-center h-72">
          <VscLoading className="animate-spin-fast text-gray-500" />
        </div>
      ) : (
        <div className="">
          <label
            htmlFor="questions"
            className="mb-2 text-sm font-semibold text-gray-900 dark:text-white flex items-center"
          >
            Qualification questions list
            <Tooltip
              itemChild={<FiInfo className="ml-1 text-gray-500" />}
              popoverChild={
                <TooltipChild
                  text={
                    "Tell the AI what questions to ask to qualify the lead. It will generally try to ask them in order. Use the divider to determine when a lead is considered qualified."
                  }
                />
              }
            ></Tooltip>
          </label>
          <DragDropContext
            onDragEnd={(result) => {
              const { source, destination } = result;
              if (!destination) return;

              const updatedQuestions = Array.from(draggableQuestions);
              const [movedQuestion] = updatedQuestions.splice(source.index, 1);
              updatedQuestions.splice(destination.index, 0, movedQuestion);

              // Find the index of the divider after the move
              const dividerIndex = updatedQuestions.findIndex(
                (q) => q.key === "divider"
              );

              // Map over the updatedQuestions and assign required status
              const updatedWithRequiredStatus: Question[] =
                updatedQuestions.map((q, index) => {
                  if (q.key === "divider") {
                    return q; // Keep the divider as is
                  } else if (index < dividerIndex) {
                    return { ...q, required: "required" };
                  } else {
                    return { ...q, required: "optional" };
                  }
                });

              // Exclude the divider when setting the questions state
              setQuestions(
                updatedWithRequiredStatus.filter((q) => q.key !== "divider")
              );
            }}
          >
            <Droppable droppableId="allQuestions">
              {(provided) => (
                <div
                  className="mb-2"
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {draggableQuestions.map((q, index) => {
                    if (q.key === "divider") {
                      return (
                        <Draggable
                          draggableId="divider"
                          index={index}
                          key="divider"
                        >
                          {(provided) => (
                            <div
                              className="relative my-2 group hover:bg-gray-50 p-2 py-3 rounded-md"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              <hr className="border-main-yellow relative top-1/2" />
                              <span className="absolute left-1/2 transform -translate-x-1/2 top-0.5 bg-white px-2 text-sm text-gray-500 group-hover:bg-gray-50">
                                Optional questions below
                              </span>
                              <div
                                {...provided.dragHandleProps}
                                className="absolute flex top-1 left-2 items-center justify-center opacity-0 group-hover:opacity-100 bg-gray-50 cursor-grab"
                              >
                                <MdDragIndicator className="text-gray-400" />
                              </div>
                            </div>
                          )}
                        </Draggable>
                      );
                    } else {
                      return (
                        <Draggable
                          key={q.key}
                          draggableId={q.key || ""}
                          index={index}
                        >
                          {(provided) => (
                            <QuestionItem
                              question={q}
                              provided={provided}
                              questions={questions}
                              setQuestions={setQuestions}
                            />
                          )}
                        </Draggable>
                      );
                    }
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <div className="mt-4 items-center relative ">
            <TextInput
              placeholder="Add question"
              value={newQuestion}
              className="border-none !bg-gray-100 w-full"
              onChange={(e) => setNewQuestion(e.target.value)}
            />
            <IconButton
              onClick={async () => {
                if (newQuestion.trim()) {
                  const key = await handleGenerateQuestionKey(newQuestion);
                  if (key) {
                    setQuestions([
                      ...questions,
                      {
                        question: newQuestion,
                        key: key,
                        required: "optional",
                      },
                    ]);
                    setNewQuestion("");
                  }
                }
              }}
              className="absolute right-2 top-1/2 transform -translate-y-1/2 hover:bg-gray-200"
              disabled={generateQuestionKeyLoading}
            >
              {generateQuestionKeyLoading ? (
                <VscLoading className="animate-spin-fast text-gray-500" />
              ) : (
                <FiPlus className="text-main-yellow" />
              )}
            </IconButton>
          </div>
        </div>
      )}
    </Card>
  );
};

export default QuestionList;
