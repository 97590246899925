import React, { useContext, useEffect } from "react";
import ConnectionItem from "../components/ConnectionItem";
import Header from "../components/Header";
import Page from "./Page";
import LinkedInConnectionModal from "./LinkedInConnectionModal";
import { toast } from "react-toastify";
import userContext from "../contexts/userContext";
import { useRemoveConnectionMutation } from "../gql/schema";

const leadGenOptions = [
  {
    name: "Apollo",
    image: "apollo.png",
    disabled: true,
  },
  {
    name: "ZoomInfo",
    image: "zi.png",
    disabled: true,
  },
  {
    name: "LeadIQ",
    image: "leadiq.jpeg",
    disabled: true,
  },
  {
    name: "Cognism",
    image: "cognism.png",
    disabled: true,
  },
  {
    name: "Upload CSV",
    image: "csv.png",
  },
  { name: "LinkedIn", image: "linkedin.png", onClick: () => {} },
];

const crms = [
  {
    name: "Hubspot",
    image: "hs.webp",
    disabled: true,
  },
  {
    name: "Salesforce",
    image: "sf.png",
    disabled: true,
  },
  {
    name: "PipeDrive",
    image: "pd.jpeg",
    disabled: true,
  },
];

const commsOptions = [
  {
    name: "Slack",
    image: "slack.png",
    onClick: () => {
      const providerName = "Slack";
      localStorage.setItem("connected_provider", providerName);
      window.open(
        `https://slack.com/oauth/v2/authorize?redirect_uri=${process.env.REACT_APP_API_ROOT}/integrating/slack_webhook/&scope=channels:history,users.profile:read,channels:read,chat:write,channels:manage,groups:read,im:write,mpim:write&client_id=${process.env.REACT_APP_SLACK_CLIENT_ID}&state=${window.location.href}`
      );
    },
  },
  {
    name: "Gmail",
    image: "gmail.png",
    disabled: true,
  },
  {
    name: "Outlook",
    image: "outlook.jpeg",
    disabled: true,
  },
];

const ConnectWith = () => {
  const [enabledList, setEnabledList] = React.useState<string[]>([]);
  const [modalOpen, setModalOpen] = React.useState<string | null>(null);
  const { user } = useContext(userContext);
  const [removeConnection] = useRemoveConnectionMutation();

  useEffect(() => {
    const hasSlack = user?.seller?.client?.config?.hasSlack;
    if (hasSlack) {
      setEnabledList([...enabledList, "Slack"]);
    }
  }, [user?.seller?.client?.config?.hasSlack]);

  const handleClick = async (name: string) => {
    if (enabledList.includes(name)) {
      setEnabledList(enabledList.filter((item) => item !== name));
      if (name === "Slack") {
        const response = await removeConnection({
          variables: { connectionName: "slack_access_token" },
        });
        if (response?.data?.removeConnection?.config?.id) {
          toast.success(`${name} successfully disconnected!`);
        } else {
          toast.error(
            response?.data?.removeConnection?.error?.message ||
              "Something went wrong"
          );
        }
      }
    } else {
      setEnabledList([...enabledList, name]);
      if (name === "LinkedIn") {
        setModalOpen("LinkedIn");
      }
    }
  };
  // Check if a provider was connected after OAuth and show the toast
  React.useEffect(() => {
    const connectedProvider = localStorage.getItem("connected_provider");
    if (connectedProvider) {
      toast.success(`${connectedProvider} successfully connected!`);
      localStorage.removeItem("connected_provider"); // Clear the localStorage after showing the toast
      enabledList.push(connectedProvider);
    }
  }, []);

  return (
    <Page childClass="w-4xl">
      <Header title="Connect with" />
      <LinkedInConnectionModal
        open={modalOpen === "LinkedIn"}
        onClose={() => setModalOpen(null)}
      />
      <div className="max-w-4xl">
        <div>
          <div className="font-semibold mb-1">Communication</div>

          <div className="flex flex-wrap mb-6">
            {commsOptions.map((connectionOption) => (
              <ConnectionItem
                key={connectionOption.name}
                name={connectionOption.name}
                image={connectionOption.image}
                selected={enabledList.includes(connectionOption.name)}
                onClick={() => {
                  handleClick(connectionOption.name);
                  if (!enabledList.includes(connectionOption.name)) {
                    connectionOption.onClick && connectionOption.onClick();
                  }
                }}
                disabled={connectionOption.disabled}
              />
            ))}
          </div>
        </div>
        <div>
          <div className="font-semibold mb-1">CRMs</div>

          <div className="flex flex-wrap mb-6">
            {crms.map((connectionOption) => (
              <ConnectionItem
                key={connectionOption.name}
                name={connectionOption.name}
                image={connectionOption.image}
                selected={enabledList.includes(connectionOption.name)}
                onClick={() => handleClick(connectionOption.name)}
                disabled={connectionOption.disabled}
              />
            ))}
          </div>
        </div>
        {/* <div>
          <div className="font-semibold mb-1">Lead generation tools</div>

          <div className="flex flex-wrap mb-6">
            {leadGenOptions.map((connectionOption) => (
              <ConnectionItem
                name={connectionOption.name}
                image={connectionOption.image}
                selected={enabledList.includes(connectionOption.name)}
                onClick={() => handleClick(connectionOption.name)}
                disabled={connectionOption.disabled}
              />
            ))}
          </div>
        </div> */}
      </div>
    </Page>
  );
};

export default ConnectWith;
