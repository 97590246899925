import React, { useContext } from "react";
import userContext from "../contexts/userContext";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const CheckUser = () => {
  const { user, userLoading } = useContext(userContext);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!userLoading && !user) {
      toast.error("You are not authorized to access this app");
      navigate("/login");
    } else if (
      !userLoading &&
      user &&
      !(user.seller.client.plan === "testing" && !user.isAdmin)
    ) {
      navigate("/dashboard");
    }
  }, [user, userLoading]);

  return null;
};

export default CheckUser;
