import { useEffect, useRef } from "react";
import Button from "./Button";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { DateTime } from "luxon";

export type ChatActionType = {
  name: string;
  id: string;
  createdAt: string;
};

export type ChatItemType = ChatMessageType | ChatActionType;

export type ChatMessageType = {
  id: string;
  message?: string;
  type: "CB" | "BY" | "ST" | "SE" | "CD";
  createdAt: DateTime;
};

type Props = {
  stepIdx: number;
  setStepIdx: (idx: number) => void;
  messages: ChatMessageType[];
  buyerEmail?: string;
  numUserConversations?: number;
};

const ActivityTimeline = (props: Props) => {
  const refs = useRef<Array<HTMLElement | null>>([]);
  const messagesEndRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (refs.current[props.stepIdx]) {
      refs.current[props.stepIdx]?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [props.stepIdx]);

  const handleRightClick = () => {
    if (props.stepIdx < props.messages.length - 1) {
      props.setStepIdx(props.stepIdx + 1);
    }
  };

  const handleLeftClick = () => {
    if (props.stepIdx > 0) {
      props.setStepIdx(props.stepIdx - 1);
    }
  };

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "ArrowRight") {
        handleRightClick();
      } else if (e.key === "ArrowLeft") {
        handleLeftClick();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [props.stepIdx, props.messages.length, props.setStepIdx]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [props.messages]);

  return (
    <div
      className="messages-section ml-4 flex-1 overflow-hidden flex flex-col"
      style={{
        width: "380px",
        maxWidth: "380px",
      }}
    >
      <div className="messages-section flex flex-col flex-1 overflow-hidden border rounded-lg mt-8 mb-4">
        <div className="relative flex-1 border-gray-200 dark:border-gray-700 overflow-auto py-4">
          {props.messages.map((t, idx) => (
            <div
              ref={(el) => (refs.current[idx] = el)}
              className={`message ${
                props.stepIdx === idx ? "bg-stone-100" : ""
              } flex py-1.5 px-4 ${
                t.type === "BY" ? "justify-end pl-6" : "justify-start pr-6"
              }  `}
              key={t?.id}
              onClick={() => props.setStepIdx(idx)}
            >
              <div
                className={`message-bubble ${
                  t.type === "BY" ? "bg-ultra-light-yellow" : "bg-stone-100"
                } rounded-lg text-gray-700 px-3 py-2 text-sm font-light`}
              >
                {t?.message}
              </div>
            </div>
          ))}
        </div>
        <div ref={messagesEndRef} />
      </div>
      {props.buyerEmail && (
        <div className="flex items-center justify-start w-full mb-2">
          <div className="rounded-full bg-gray-100 p-1 h-12 w-12 text-xl flex items-center justify-center">
            {props.buyerEmail?.charAt(0).toUpperCase()}
          </div>
          <div className="ml-2">
            <div className="text-base">{props.buyerEmail}</div>
            {props.numUserConversations && (
              <div className="text-gray-500 text-sm leading-3">
                {props.numUserConversations} search session
                {props.numUserConversations > 1 ? "s" : ""}
              </div>
            )}
          </div>
        </div>
      )}
      <div className="flex justify-between flex-wrap">
        <div className="flex">
          <Button
            variant="outlined"
            className="mr-2"
            disabled={props.stepIdx === 0}
            onClick={() => props.setStepIdx(props.stepIdx - 1)}
          >
            <FiChevronLeft className="mr-1" />
            Previous
          </Button>
          <Button
            variant="outlined"
            className="mr-4"
            disabled={props.stepIdx === props.messages.length - 1}
            onClick={() => props.setStepIdx(props.stepIdx + 1)}
          >
            Next
            <FiChevronRight className="ml-1" />
          </Button>
          <div className="text-gray-500 flex items-center justify-center">
            {props.stepIdx + 1}/{props.messages.length}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivityTimeline;
