import React from "react";

const useEnterSubmit = (handler: Function, conditions: boolean = true) => {
  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === "Enter" && conditions) {
      handler();
    }
  };
  React.useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress, conditions]);

  return;
};

export default useEnterSubmit;
