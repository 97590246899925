import React from "react";
import Tooltip, { TooltipChild } from "../components/Tooltip";

type Props = {
  name: string;
  path: string;
  icon: React.ReactNode;
  selected: boolean;
  onClick: () => void;
  disabled?: boolean;
  collapsed?: boolean;
};

const SidebarItem = (props: Props) => {
  const [showText, setShowText] = React.useState(false);
  React.useEffect(() => {
    if (props.collapsed) {
      setShowText(false);
    } else {
      setTimeout(() => {
        setShowText(true);
      }, 80);
    }
  }, [props.collapsed]);
  return (
    <Tooltip
      itemChild={
        <div
          tabIndex={0}
          className={`${
            props.selected ? "bg-ultra-light-yellow text-gray-900" : ""
          } flex items-center rounded-lg text-gray-500 h-10 w-full py-2 my-2 px-8 hover:bg-light-yellow cursor-pointer active:opacity-70 ${
            props.disabled ? "!cursor-auto !bg-white" : "hover:text-gray-900"
          }`}
          onClick={props.disabled ? () => {} : props.onClick}
        >
          <div>{props.icon}</div>
          {showText && <div className="text-base ml-3">{props.name}</div>}
        </div>
      }
      popoverChild={
        <TooltipChild text={props.disabled ? "Coming soon!" : ""} />
      }
    />
  );
};

export default SidebarItem;
