import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../components/Button";
import Header from "../components/Header";
import ViewPreview from "../components/ViewPreview";
import Page from "./Page";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useListViewsQuery, useUpdateViewListMutation } from "../gql/schema";

type ViewType = {
  id: string;
  title: string;
  image: string;
  favorite: boolean;
  archived: boolean;
};

type ViewItem = {
  id: string;
  title: string;
  image: string;
  favorite: boolean;
  archived: boolean;
  order?: number;
};

const ViewsList = () => {
  const navigate = useNavigate();
  const [views, setViews] = React.useState<ViewItem[]>([]);
  const [updateDemoEvents] = useUpdateViewListMutation();
  const { data, loading } = useListViewsQuery();

  React.useEffect(() => {
    if (data?.listViews) {
      // setViews(
      //   data.listViews?.slice().map((view) => ({
      //     id: view?.id,
      //     title: view?.preview || "",
      //     image: view?.image || "",
      //     order: view?.order || 0,
      //     favorite: view?.default || false,
      //     archived: view?.archived || false,
      //   })) || []
      // );
    }
  }, [data?.listViews]);

  const favoriteViews = React.useMemo(
    () =>
      views
        .filter((view) => view.favorite && !view.archived)
        ?.sort((a, b) => (a?.order || 0) - (b?.order || 0)),
    [views]
  );

  const allViews = React.useMemo(
    () =>
      views
        .filter((view) => !view.favorite && !view.archived)
        ?.sort((a, b) => (a?.order || 0) - (b?.order || 0)),
    [views]
  );

  const archivedViews = React.useMemo(
    () =>
      views
        .filter((view) => view.archived)
        ?.sort((a, b) => (a?.order || 0) - (b?.order || 0)),
    [views]
  );

  const onDragEnd = async (result: any) => {
    try {
      const { source, destination } = result;

      // Dropped outside the list
      if (!destination) return;

      // Find the dragged item in the main views array
      const draggedItem = views.find((view) => view.id === result.draggableId);

      // If the item is not found, exit the function
      if (!draggedItem) return;

      // Create a new array without the dragged item
      const newViews = views.filter((view) => view.id !== result.draggableId);

      // Update the item properties based on the destination droppableId
      switch (destination.droppableId) {
        case "favorites":
          draggedItem.favorite = true;
          draggedItem.archived = false;
          break;
        case "all-views":
          draggedItem.favorite = false;
          draggedItem.archived = false;
          break;
        case "archived":
          draggedItem.favorite = false;
          draggedItem.archived = true;
          break;
        default:
          break;
      }

      // Insert the item into the correct position in the newViews array
      // The destination.index needs to be adjusted to the index in the main array
      const destinationIndex = getDestinationIndex(
        destination,
        favoriteViews,
        allViews
      );

      newViews.splice(destinationIndex, 0, draggedItem);

      // Update the state with the new views array
      setViews(newViews);

      const formattedViews = newViews.map((view) =>
        view.id === draggedItem.id
          ? {
              id: view.id,
              order: newViews.indexOf(view),
              archived: destination.droppableId === "archived",
              default: destination.droppableId === "favorites",
            }
          : {
              id: view.id,
              order: newViews.indexOf(view),
              archived: archivedViews.map((a) => a.id).includes(view.id),
              default: favoriteViews.map((a) => a.id).includes(view.id),
            }
      );

      await updateDemoEvents({
        variables: {
          viewsList: formattedViews,
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  // Helper function to calculate the correct destination index in the main views array
  function getDestinationIndex(
    destination: any,
    favoriteViews: ViewType[],
    allViews: ViewType[]
  ) {
    switch (destination.droppableId) {
      case "favorites":
        return destination.index;
      case "all-views":
        return destination.index + favoriteViews.length;
      case "archived":
        return destination.index + favoriteViews.length + allViews.length;
      default:
        return destination.index;
    }
  }
  console.log("views", views);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Page childClass="w-2xl">
        <Header title="Views" />
        <div className="">
          <div className="flex justify-start items-end mb-4">
            {loading ? (
              <div className="text-gray-500">Loading...</div>
            ) : views ? (
              <div className="text-gray-500">{views.length} views</div>
            ) : (
              <div />
            )}
            {/* <Button onClick={() => {}}>Add view</Button> */}
          </div>
          {loading ? (
            <div />
          ) : views.length === 0 ? (
            <div className="text-gray-500 text-xl mb-2">No views yet</div>
          ) : (
            <>
              <div className="">
                <div className="text-gray-500 text-xl mb-2">Default views</div>
              </div>

              <Droppable droppableId="favorites" direction="horizontal">
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className="flex flex-wrap mb-4"
                    style={{ minHeight: "176px" }}
                  >
                    {favoriteViews.map((view, index) => (
                      <Draggable
                        key={view.id}
                        draggableId={view.id}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <ViewPreview
                              id={view.id}
                              title={view.title}
                              image={view.image}
                              onClick={() => navigate(`/views/${view.id}`)}
                              favorite={view.favorite}
                              onFavoriteClick={() => {}}
                              archived={view.archived}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                  </div>
                )}
              </Droppable>

              <div className="">
                <div className="text-gray-500 text-xl mb-2">All views</div>
              </div>
              <Droppable droppableId="all-views" direction="horizontal">
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className="flex flex-wrap mb-4"
                    style={{ minHeight: "176px" }}
                  >
                    {allViews.map((view, index) => (
                      <Draggable
                        key={view.id}
                        draggableId={view.id}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <ViewPreview
                              id={view.id}
                              title={view.title}
                              image={view.image}
                              onClick={() => navigate(`/views/${view.id}`)}
                              favorite={view.favorite}
                              onFavoriteClick={() => {}}
                              archived={view.archived}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                  </div>
                )}
              </Droppable>

              <div className="border-t-2 ">
                <div className="text-gray-500 text-xl mb-2 mt-4">Archived</div>
              </div>
              <Droppable droppableId="archived" direction="horizontal">
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className="flex flex-wrap mb-4"
                    style={{ minHeight: "176px" }}
                  >
                    {archivedViews.map((view, index) => (
                      <Draggable
                        key={view.id}
                        draggableId={view.id}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <ViewPreview
                              id={view.id}
                              title={view.title}
                              image={view.image}
                              onClick={() => navigate(`/views/${view.id}`)}
                              favorite={view.favorite}
                              onFavoriteClick={() => {}}
                              archived={view.archived}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                  </div>
                )}
              </Droppable>
            </>
          )}
        </div>
      </Page>
    </DragDropContext>
  );
};

export default ViewsList;
