import React, { useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Page from "./Page";
import ConversationReviewScreen from "./ConversationReviewScreen";
import ActivityTimeline, {
  ChatMessageType,
} from "../components/ActivityTimeline";
import Prompts from "../components/Prompts";
import { useGetConversationQuery } from "../gql/schema";
import { DateTime } from "luxon";

type SampleMessage = {
  id: string;
  createdAt?: string;
  messageViews: {
    view: {
      image: string | null;
      url: string;
      promptText: string;
      clickEvent: string;
    };
  }[];
  body?: string;
  sender?: string;
  sentiment?: string | null;
  feedback: {
    positive: boolean;
    text: string;
  }[];
};

const sampleMessages: SampleMessage[] = [
  {
    id: "1",
    createdAt: "2022-10-15T08:00:00",
    messageViews: [
      {
        view: {
          image: require("../images/deck-starter.jpg"),
          url: "https://example.com/michael",
          promptText:
            "What does Telepathic do?|How much does it cost?|Who is Telepathic suited for?",
          clickEvent: '{"x": 100, "y": 50, "width": 200, "height": 150}',
        },
      },
    ],
    body: "What do I need to do to get set up with Telepathic?",
    sender: "BY",
    sentiment: "positive",
    feedback: [],
  },
  {
    id: "2",
    createdAt: "2022-10-15T08:05:00",
    messageViews: [
      {
        view: {
          image: require("../images/deck-setup.jpg"),
          url: "https://app.amplitude.com/analytics/demo/settings/users/joined",
          promptText:
            "Who should set up Telepathic?|How long does it take to add a resource?",
          clickEvent: '{"x": 1330, "y": 20, "width": 180, "height": 120}',
        },
      },
    ],
    body: "You can export this list by clicking on the 'Export CSV' button on the top right corner of the page.",
    sender: "CB",
    sentiment: null,
    feedback: [
      {
        positive: true,
        text: "Great response, very helpful!",
      },
    ],
  },
];
const maxHeight = 683;

const Activity = () => {
  const { id, messageId } = useParams();
  const navigate = useNavigate();
  const isSampleData = id === "sample";

  const { data } = useGetConversationQuery({
    variables: { id: id! },
    skip: id === "sample",
  });

  const sortedMessages = React.useMemo(
    () =>
      isSampleData
        ? sampleMessages
        : data?.getConversation?.messages
            .slice()
            .sort(
              (a, b) =>
                new Date(a.createdAt).getTime() -
                new Date(b.createdAt).getTime()
            ) || [],
    [data, isSampleData]
  );

  const formattedMessages: ChatMessageType[] = React.useMemo(
    () =>
      sortedMessages.map((msg) => ({
        id: msg.id,
        message: msg.body || "",
        type: msg.sender as "CB" | "BY" | "ST" | "SE" | "CD",
        createdAt: DateTime.fromISO(msg.createdAt),
      })),
    [sortedMessages]
  );

  const initialStepIdx = messageId
    ? sortedMessages.findIndex((msg) => msg.id === messageId)
    : 0;
  const [stepIdx, setStepIdx] = React.useState(initialStepIdx);

  const currentMessage = React.useMemo(() => {
    return sortedMessages[stepIdx];
  }, [stepIdx, sortedMessages]);

  const currentView = React.useMemo(() => {
    const views = currentMessage?.messageViews || [];
    const firstView = views[0]?.view;

    if (firstView?.image) {
      return firstView;
    }

    // Find the next earliest view with an image by descending in index
    for (let i = stepIdx - 1; i >= 0; i--) {
      const previousMessageViews = sortedMessages[i]?.messageViews || [];
      const previousView = previousMessageViews[0]?.view;
      if (previousView?.image) {
        return previousView;
      }
    }

    return null;
  }, [currentMessage, stepIdx, sortedMessages]);

  React.useEffect(() => {
    if (messageId !== sortedMessages[stepIdx]?.id) {
      if (!!sortedMessages[stepIdx]?.id) {
        navigate(`/activity/${id}/messages/${sortedMessages[stepIdx]?.id}`);
      } else {
        setStepIdx(
          sortedMessages.findIndex((msg) => msg.id === messageId) || 0
        );
      }
    }
  }, [stepIdx, sortedMessages, id, navigate, messageId]);

  return (
    <Page
      childClass="max-w-7xl !pb-0 !overflow-hidden"
      parentClass="!overflow-hidden"
    >
      <Header title="Activity" />
      <div
        className="flex w-full flex-1 overflow-hidden"
        style={{
          maxHeight: maxHeight,
        }}
      >
        <div
          className="large-left flex flex-col"
          style={{ width: 832, height: maxHeight, maxHeight: maxHeight }}
        >
          <ConversationReviewScreen imageUrl={currentView?.image || null} />

          <Prompts
            prompts={
              currentView?.promptText?.split(/\n|\|/)?.map((p) => p?.trim()) ||
              []
            }
          />
        </div>
        <ActivityTimeline
          stepIdx={stepIdx}
          setStepIdx={setStepIdx}
          messages={formattedMessages}
          buyerEmail={
            data?.getConversation?.buyer?.encryptedEmail ||
            data?.getConversation?.buyer?.user?.email ||
            ""
          }
          numUserConversations={
            data?.getConversation?.buyer?.conversations?.length || 0
          }
        />
      </div>
    </Page>
  );
};

export default Activity;
